import { EventEmitter } from "events";
export var Orientation;
(function (Orientation) {
    Orientation[Orientation["Landscape"] = 0] = "Landscape";
    Orientation[Orientation["Portrait"] = 1] = "Portrait";
})(Orientation || (Orientation = {}));
export class Constraint {
    constructor(o, l, h) {
        this.emitter = new EventEmitter();
        this.orientation = o;
        this.low = l;
        this.high = h;
        window.addEventListener('resize', this.processResize.bind(this));
    }
    addEventListener(name, fn) {
        this.emitter.addListener(name, fn);
    }
    processResize() {
        console.debug(`onresize triggered for ${this}`);
        const scale = window.devicePixelRatio;
        const w = Math.floor(innerWidth * scale);
        const h = Math.floor(innerHeight * scale);
        const or = w < h ? Orientation.Portrait : Orientation.Landscape;
        if (or == this.orientation && ((this.orientation == Orientation.Landscape && this.low <= w && w <= this.high) ||
            (this.orientation == Orientation.Portrait && this.low <= h && h <= this.high))) {
            this.emitter.emit('active', this);
        }
    }
    toString() {
        return `${Orientation[this.orientation]}, ${this.low} -- ${this.high}`;
    }
}
function getConstraints(o, n) {
    const mcs = [];
    let previous = 0;
    for (let i = 0; i < n.length; i++) {
        if (i != 0) {
            previous++;
        }
        const v = n[i];
        mcs.push(new Constraint(o, previous, v));
        previous = v;
    }
    if (n.length > 0) {
        mcs.push(new Constraint(o, previous + 1, Infinity));
    }
    return mcs;
}
export function generateConstraints(widths, heights) {
    return [
        ...getConstraints(Orientation.Landscape, widths),
        ...getConstraints(Orientation.Portrait, heights)
    ];
}
