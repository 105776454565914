var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter } from 'events';
import { Orientation } from "./constraint";
const p = new Promise((resolve, _) => {
    const img = new Image();
    img.onload = () => resolve(img.width > 0 && img.height > 0);
    img.onerror = () => resolve(false);
    img.src = 'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA';
});
export class ImageChangedEvent {
    constructor(url, date, location, mainColor) {
        this.url = url;
        this.date = date;
        this.location = location;
        this.mainColor = mainColor;
    }
}
export class BackgroundManager {
    constructor(name) {
        this.name = name;
        this.emitter = new EventEmitter();
    }
    addEventListener(name, fn) {
        this.emitter.on(name, fn);
    }
    updateConstraint(c) {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug(`trying to update constraint with ${c}`);
            if (!this.requiresUpdate(c)) {
                return;
            }
            const params = { format: (yield p) ? 'webp' : 'jpg' };
            if (this.name != null) {
                params['name'] = this.name;
            }
            if (c.high != Infinity) {
                switch (c.orientation) {
                    case Orientation.Landscape:
                        params['width'] = c.high;
                        break;
                    case Orientation.Portrait:
                        params['height'] = c.high;
                        break;
                }
            }
            const f = yield fetch('/background?' + new URLSearchParams(params));
            const json = yield f.json();
            this.name = json.name;
            const event = new ImageChangedEvent(json.url, json.date, json.location, json.mainColor);
            this.emitter.emit('change', event);
            this.currentConstraint = c;
        });
    }
    requiresUpdate(c) {
        return this.currentConstraint === undefined ||
            this.currentConstraint.orientation != c.orientation ||
            this.currentConstraint.high < c.high;
    }
}
