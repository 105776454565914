/*
    Eventually by HTML5 UP
    html5up.net | @ajlkn
    Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import './css/style.css';
import './css/fontawesome.css';
import './css/fontawesome-brands.css';
import './css/fontawesome-solid.css';
import breakpoints from '../config/breakpoints.json';
import { generateConstraints } from "./ts/constraint";
import { BackgroundManager } from "./ts/bgmgr";
(function () {
    return __awaiter(this, void 0, void 0, function* () {
        // Play initial animations on page load.
        window.onload = () => {
            window.setTimeout(() => {
                document.body.classList.remove('is-preload');
            }, 100);
        };
        const imgName = new URLSearchParams(window.location.search).get('img');
        const bgmgr = new BackgroundManager(imgName);
        bgmgr.addEventListener('change', ice => {
            document.getElementById('bg').style.backgroundImage = `url(${ice.url})`;
            document.getElementById('where').innerHTML = ice.location;
            document.getElementById('when').innerHTML = ice.date;
            document.querySelector('meta[name=theme-color]').setAttribute('content', ice.mainColor);
        });
        generateConstraints(breakpoints.widths, breakpoints.heights).forEach(c => c.addEventListener('active', bgmgr.updateConstraint.bind(bgmgr)));
        dispatchEvent(new Event('resize'));
    });
})();
